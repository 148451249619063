.portfolio__container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.5rem;
}

.portfolio__item {
	background-color: var(--color-bg-variant);
	padding: 1.3rem;
	border-radius: 2rem;
	border: 1px solid transparent;
	transition: var(--transition);
}

.portfolio__item:hover {
	border-color: var(--color-primary-variant);
	background-color: transparent;
}

.portfolio__item-image {
	border-radius: 1.5rem;
	overflow: hidden;
}

.portfolio__item h3 {
	margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
	display: flex;
	gap: 1rem;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
	.portfolio__container {
		grid-template-columns: 1fr 1fr;
		gap: 1.2rem;
	}
}
/* =========== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
	.portfolio__container {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}